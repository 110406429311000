<template>
    <div>
        <Header/>
        <div class=" v_content">
        <div style="padding:15px;margin-bottom:20px;">
            <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                <div style="text-align:left;margin-top:20px;">
                    <h5><strong>{{$t('faq_header')}}</strong></h5>
                </div>
                <div class="faq_card">
                    <b-card no-body v-for="faq, index in faqData" :key="index" style="margin-top:15px;" v-b-toggle="'collapse_' + index">
                        <b-card-header>
                            {{ $i18n.locale === 'th' ? faq.q_th : faq.q_en }}
                            <b-button  style="float:right;padding:0px;" variant="link">
                                <!-- <i class="fas fa-chevron-right"></i> -->
                                <i class="fas fa-chevron-down"></i>
                            </b-button>
                        </b-card-header>
                        <b-collapse :id="'collapse_' + index" accordion="my-accordion">
                            <b-card-body>
                                <label v-html="$i18n.locale === 'th' ? faq.a_th : faq.a_en"></label>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'

export default {
    name : 'faq',
    components : {
        Header,
        Footer
    },
    data () {
        return {
            faqData : [
                {
                    "q_th" : "ค่ามาตรฐานฝุ่นละออง PM2.5 เป็นเท่าใด",
                    "a_th" : "ค่ามาตรฐานของค่าเฉลี่ย 24 ชั่วโมง ไม่ควรเกิน 37.5 ไมโครกรัมต่อลูกบาศก์เมตร\nค่ามาตรฐานของค่าเฉลี่ยราย 1 ปี ไม่ควรเกิน 15 ไมโครกรัมต่อลูกบาศก์เมตร",
                    "q_en" : "What is the standard value of PM2.5 ?",
                    "a_en" : "The standard value of the 24 hour average should not exceed 37.5 micrograms per cubic meter.\nThe standard value of the annual average should not exceed 15 micrograms per cubic meter.",
                },
                {
                    "q_th" : "ดัชนีคุณภาพอากาศคืออะไร",
                    "a_th" : "ดัชนีคุณภาพอากาศ หรือ เอคิวไอ (AQI: Air Quality Index) เป็นการนำข้อมูลคุณภาพอากาศมาแปลงเป็นระดับผลกระทบต่อสุขภาพ ใช้สีเป็นสัญลักษณ์แสดงถึงระดับความรุนแรง สังเกตได้ว่าค่าของ AQI จะไม่มีหน่วย แต่ค่าที่แสดงถึงปริมาณของสารมลพิษ จะมีหน่วยเป็นความเข้มข้นของสาร เช่น ไมโครกรัมต่อลูกบาศก์เมตร เป็นต้น",
                    "q_en" : "What is the air quality index?",
                    "a_en" : "The Air Quality Index or AQI (AQI: Air Quality Index) is the process of converting air quality data into health impact levels. Use colors as a symbol of severity. Note that the AQI value does not have units, but values represent the amount of pollutants. There are units of concentration of substances such as micrograms per cubic meter, etc."
                },
                {
                    "q_th" : "การตีความระดับสี",
                    "a_th" : "ดัชนีคุณภาพอากาศของประเทศไทย แสดงระดับสี 5 ระดับ สีฟ้า เขียว และเหลือง แสดงระดับมลพิษทางอากาศที่ยังอยู่ในเกณฑ์มาตรฐาน โดยสีเหลืองคือใกล้ถึงค่ามาตรฐาน อาจจะมีผลกระทบต่อผู้ที่อ่อนไหวกับมลพิษทางอากาศเป็นพิเศษได้ และเมื่อเกินค่ามาตรฐานจะเป็นสีส้ม และสีแดง โดยจะส่งผลกระทบต่อประชาชนทั่วไปในวงกว้างได้",
                    "q_en" : "Interpretation of color scale",
                    "a_en" : "Thailand's air quality index shows 5 color levels, blue, green and yellow, showing the level of air pollution that is still within the standard. where yellow is close to the standard value. May affect people who are especially sensitive to air pollution. And when it exceeds the standard, it will be orange and red, which will affect the general public in a wide range."
                },
                {
                    "q_th" : "ทำไม AQI ของประเทศไทยไม่เท่ากับของสหรัฐอเมริกา",
                    "a_th" : "ณ ค่าปริมาณมลพิษที่เท่ากัน เมื่อแปลงเป็นระดับสี AQI จะพบว่าของไทยกับต่างประเทศ ใช้สีที่แตกต่างกัน อย่างไรก็ตาม ความแตกต่างอยู่ที่การแสดงสีเท่านั้น ขอให้ประชาชนทำความเข้าใจระดับคำเตือนทางสุขภาพ จะพบว่าคำเตือนทางสุขภาพของไทยและต่างประเทศตรงกัน",
                    "q_en" : "Why is Thailand's AQI not the same as that of the United States?",
                    "a_en" : "at the same amount of pollution When converted to AQI color scale, it is found that Thai and foreign Different colors are used, however, the difference lies only in the representation of the colors. Ask the public to understand the level of health warnings. Will find that the health warnings in Thailand and abroad are the same."
                },
                {
                    "q_th" : "ทำไมค่าของ Air4Thai จึงต่างจากเว็บไซต์อื่น",
                    "a_th" : "การชั่งตวงตรวจวัดมักจะให้ค่าไม่เท่ากันตามวิธีการ เช่น เครื่องชั่งระบบดิจิตอล กับเครื่องชั่งระบบตุ้มน้ำหนัก จะให้ค่าที่แตกต่างกันเป็นต้น เครื่องมือตรวจวัดจึงจำเป็นต้องได้รับมาตรฐานรับรองความแม่นยำเที่ยงตรง ทั้งนี้ กรมควบคุมมลพิษ ใช้เครื่องมือที่ได้รับการรับรองมาตรฐานจาก U.S. EPA และได้รับการดูแลรักษาอย่างสม่ำเสมอเพื่อให้แน่ใจว่าให้ค่าถูกต้องตลอดเวลา เราจึงสามารถยืนยันความถูกต้องได้ในระดับนานาชาติ สำหรับเว็บไซต์อื่น ผู้ใช้งานควรศึกษาว่าเครื่องมือเหล่านั้นผ่านการรับรองมาตรฐานหรือไม่ อย่างไร เพื่อพิจารณาความแม่นยำเที่ยงตรงของข้อมูล",
                    "q_en" : "Why is Air4Thai value different from other websites?",
                    "a_en" : "Weighing and measurements often give different values ​​according to methods such as digital scales. with weighing system will give different values ​​and so on. Measurement instruments therefore need to be certified for accuracy and precision. The Pollution Control Department Use tools that are U.S. EPA certified and maintained regularly to ensure they are always accurate. We are therefore able to verify its authenticity at an international level. for another website Users should study whether the tools are certified or not and how to determine the accuracy of the data."
                },
                {
                    "q_th" : "ความแตกต่างของค่าเฉลี่ย 1 ชั่วโมง และ 24 ชั่วโมง",
                    "a_th" : "ผลการตรวจวัดมลพิษทางอากาศที่เป็น ค่าเฉลี่ย 1 ชั่วโมงจะเปลี่ยนแปลงขึ้น-ลง อย่างรวดเร็ว ในขณะที่ค่าเฉลี่ย 24 ชั่วโมงจะค่อนข้างคงที่ ตามรายงานขององค์กรอนามัยโลก (WHO) ผลกระทบทางสุขภาพของฝุ่นละอองจะเน้นไปทางผลกระทบระยะกลาง-ยาว เช่น ก่อให้เกิดโรคภูมิแพ้ โรคปอดเรื้อรัง โรคมะเร็ง เป็นต้น ดังนั้น WHO จึงได้แนะนำค่ามาตรฐานสำหรับค่าเฉลี่ยระยะ 24 ชั่วโมง และระยะ 1 ปี สำหรับข้อมูลราย 1 ชั่วโมง มักนิยมใช้เพื่อการเฝ้าระวังระยะสั้น โดยเฉพาะผู้ที่มีความอ่อนไหวเป็นพิเศษกับมลพิษทางอากาศอาจจะมีอาการได้ เช่น โรคความดันกำเริบ หอบหืดกำเริบ เป็นต้น",
                    "q_en" : "Difference of 1-hour and 24-hour mean",
                    "a_en" : "The 1-hour average air pollution measurement results fluctuate rapidly, while the 24-hour average is relatively stable. According to the World Health Organization (WHO), the health impacts of particulate matter are focused on medium- to long-term effects, such as causing allergies. chronic lung disease, cancer, etc. Therefore, the WHO recommends standard values ​​for 24-hour and 1-year averages for 1-hour data, often used for short-term surveillance. Especially those who are especially sensitive to air pollution may experience symptoms such as hypertension, asthma attacks, etc."
                },
                {
                    "q_th" : "พื้นที่ที่ใกล้บ้านของฉันไม่มีข้อมูลตรวจวัด",
                    "a_th" : "กรมควบคุมมลพิษ กำลังเร่งพัฒนาเพื่อขยายเครือข่ายการตรวจวัดให้ครอบคลุม ในระยะที่ยังไม่มีข้อมูลในพื้นที่ของท่าน ประชาชนสามารถใช้ข้อมูลจากสถานีใกล้เคียง และมีกิจกรรมคล้ายกับพื้นที่ของตนเอง โดยพื้นที่แบ่งเป็น เช่น เป็นพื้นที่เขตเมือง พื้นที่อุตสาหกรรม พื้นที่เกษตรกรรม เป็นต้น ทั้งนี้ หากเกิดเหตุการณ์มลพิษทางอากาศเกินค่ามาตรฐาน ประชาชนจะสามารถรับรู้มลพิษได้ด้วยตนเอง เช่น กลิ่นไม่พึงประสงค์ การหายใจอึดอัด ความระคายเคือง เป็นต้น ควรติดต่อสำนักงานทรัพยากรธรรมชาติและสิ่งแวดล้อมในจังหวัดของท่าน หรือติดต่อกรมควบคุมมลพิษ สายด่วน 1650",
                    "q_en" : "The area near my home does not have measurement data.",
                    "a_en" : "Pollution Control Department It is accelerating development to expand its measurement network. In the period that there is no information in your area Citizens can use information from nearby stations. and have activities similar to their own area The area is divided into, for example, an urban area. industrial area agricultural areas, etc., if the air pollution exceeds the standard People will be able to perceive pollution by themselves, such as unpleasant odors. uncomfortable breathing irritation, etc. should contact the Office of Natural Resources and the Environment in your province. or contact the Pollution Control Department, hotline 1650"
                },
                {
                    "q_th" : "ประชาชนทั่วไป กับ ประชาชนกลุ่มเสี่ยง หมายถึงใครบ้าง",
                    "a_th" : "ประชาชนกลุ่มเสี่ยง หมายถึง ประชาชนที่อาจเสี่ยงต่อการได้รับผลกระทบต่อสุขภาพจากมลพิษทางอากาศมากกว่าประชาชนทั่วไป เช่น ผู้สูงอายุมากกว่า 60 ปีขึ้นไป เด็กเล็กอายุไม่เกิน 5 ปี หญิงตั้งครรภ์ ผู้ป่วยโรคระบบหัวใจและหลอดเลือด และโรคระบบทางเดินหายใจ เป็นต้น<br />ส่วนประชาชนทั่วไป หมายถึง ประชาชนนอกเหนือจากประชาชนกลุ่มเสี่ยง",
                    "q_en" : "What are 'People who need special health care' and 'General public' meaning ?",
                    "a_en" : "'People who need special health care' mean people who may be more at risk of being affected by air pollution than the general public, such as the elderly over 60 years old, young children under 5 years old, pregnant women, cardiovascular disease patients, and respiratory disease, etc.<br /> and 'General public', meaning people other than People who need special health care",
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.faq_card{
    text-align: left;
    margin-top: 30px;
}
</style>